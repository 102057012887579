import { ImageAttributes, InstanceData } from './general';

export type BusinessRegistryLocalizationData = {
  attributes: {
    slug: string;
    locale: string;
  };
};

export type BusinessRegistryData = {
  attributes: {
    slug: string;
    locale: string;
    localizations: {
      data: Array<BusinessRegistryLocalizationData>;
    };
  };
};

export type BusinessHit = {
  id: string;
  slug: string;
  address: string;
  activitiesDescription: string;
  canOperateInCommunities: Array<string>;
  city: string;
  creeCertified: boolean;
  generalContractorCategories: Array<string>;
  legalBusinessName: string;
  naicsCategories: Array<string>;
  nameOfRepresentative: string;
  neq: string;
  owners: Array<{ name: string; email: string; phone: string }>;
  phone: string;
  postalCode: string;
  province: string;
  publicFacingDescription: string;
  publicFacingLegalBusinessName: string;
  seasonsOfOperation: Array<string>;
  specializedContractorCategories: Array<string>;
  thumbnail: {
    data: {
      attributes: ImageAttributes;
    };
  };
  website: string;
};

export type BusinessData = InstanceData & {
  id: number;
  attributes: BusinessDataAttributes;
};

export type BusinessDataAttributes = {
  slug: string;
  basedInCommunity: {
    data: {
      attributes: {
        name: string;
      };
    };
  };
  canOperateInCommunities: {
    data: Array<{
      attributes: {
        name: string;
      };
    }>;
  };
  coverPicture: {
    data: {
      attributes: ImageAttributes;
    };
  };
  creeCertified: boolean;
  dateOfStartUp: string;
  email: string;
  facebook: string;
  generalContractorCategories: {
    data: Array<{
      attributes: {
        name: string;
      };
      id: number;
    }>;
  };
  instagram: string;
  legalStructure: {
    data: {
      id: number;
      attributes: {
        name: string;
        locale: string;
        localizations: Localizations
      };
    };
  };
  linkedin: string;
  logo: {
    data: {
      attributes: ImageAttributes;
    };
  };
  naicsCategories: {
    data: Array<{
      id: number;
      attributes: {
        name: string;
      };
    }>;
  };
  naicsCategoriesAllIds: Array<number>;
  operationalCommunitiesAllIds: Array<number>;
  canManageOpportunities: boolean;
  seasonsOfOperationAllIds: Array<number>;
  generalContractorCategoriesAllIds: Array<number>;
  specializedContractorCategoriesAllIds: Array<number>;
  neq: string;
  numberOfEmployees: string;
  // Normally we have public properties for email and phone, but the API only return them if they are public, so it's not exposed by the api call return
  owners: Array<{
    email: string;
    name: string;
    phone: string;
    ownershipPercentage: string;
    isEmailPublic: boolean;
    isPhonePublic: boolean;
  }>;
  percentageOfCreeEmployees: string;
  phone: string;
  pictures: Array<{
    picture: {
      data: {
        attributes: ImageAttributes;
      };
    };
    caption: string;
  }>;
  publicFacingDescription: string;
  publicFacingLegalBusinessName: string;
  rbqInfo: {
    id: number;
    rbq: string;
    respondentName: string;
    respondentPhoneNumber: string;
    respondentMobileNumber: string;
    respondentEmail: string;
    respondentAddress: BusinessAddressAttributes;
  };
  schedule: Array<{
    days: string;
    hours: string;
  }>;
  seasonsOfOperation: {
    data: Array<{
      attributes: {
        name: string;
      };
    }>;
  };
  specializedContractorCategories: {
    data: Array<{
      attributes: {
        name: string;
      };
      id: number;
    }>;
  };
  website: string;
  localizations: {
    data: Array<{
      id: number;
      attributes: {
        slug: string;
        publicFacingLegalBusinessName: string;
        locale: string;
      };
    }>;
  };
  status: string;
  headOfficeAddress: BusinessAddressAttributes;
  legalBusinessName: string;
  administrativeOffices: Array<{
    sameAsHeadOffice: boolean;
    phoneNumber: string;
    faxNumber: string;
    address: BusinessAddressAttributes;
  }>;
  otherPermanentOfficesOrPlaces: {
    notApplicable: boolean;
    addresses: Array<BusinessAddressAttributes>;
  };
  businessRepresentative: {
    name: string;
    function: string;
    phoneNumber: string;
    email: string;
    address: BusinessAddressAttributes;
  };
  certificationsAndAccreditations: string;
  localSuppliers: {
    goodsProportion: {
      data: {
        id: number;
        attributes: {
          name: string;
          locale: string;
          localizations: Localizations
        };
      };
    };
    itemsAvailability: string;
    suppliers: Array<{
      fullName: string;
      address: BusinessAddressAttributes;
    }>;
  };
  investors: Array<{
    names: string;
  }>;
  realEstateProperties: {
    notApplicable: boolean;
    addresses: Array<BusinessAddressAttributes>;
  };
  locationOfImportantAssets: {
    notApplicable: boolean;
    assetLocations: Array<{
      assetType: string;
      address: BusinessAddressAttributes;
    }>;
  };
  commercialPremises: {
    notApplicable: boolean;
    addresses: Array<BusinessAddressAttributes>;
  };
  doNotOwnsImmovableOrMovableProperty: boolean;
  approximateValueOfAssets: {
    data: {
      id: number;
      attributes: {
        name: string;
        locale: string;
        localizations: Localizations
      };
    };
  };
  approximatePercentageOfAssets: {
    data: {
      id: number;
      attributes: {
        name: string;
        locale: string;
        localizations: Localizations
      };
    };
  };
  shareholders: {
    hasUnanimousAgreement: boolean;
    information: Array<{
      fullName: string;
      address: BusinessAddressAttributes;
    }>;
  };
  legalRepresentatives: Array<{
    fullName: string;
    address: BusinessAddressAttributes;
  }>;
  legalRepresentativesMeetingLocations: Array<{
    meetingDate: string;
    isMeetingVirtual: boolean;
    attendeesLocation: string;
    address: BusinessAddressAttributes;
  }>;
  shareholdersMeetingLocations: Array<{
    meetingDate: string;
    isMeetingVirtual: boolean;
    attendeesLocation: string;
    address: BusinessAddressAttributes;
  }>;
  bankAccount: BusinessAddressAttributes;
  managementOfPayroll: BusinessAccountingAttributes;
  processOfPayroll: BusinessAccountingAttributes;
  generalAccounting: BusinessAccountingAttributes;
  administrativeRecords: Array<BusinessAddressAttributes>;
  employees: Array<{
    fullName: string;
    address: BusinessAddressAttributes;
  }>;
  soleProprietor: BusinessAddressAttributes;
  recruitmentAndWorkforce: {
    labourForceInformationAgreement: boolean;
    totalNumberOfEmployeesLastTwelveMonths: number;
    fullTimeEmployees: number;
    fullTimeEmployeesInCreeFirstNation: number;
    partTimeEmployees: number;
    partTimeEmployeesInCreeFirstNation: number;
    permanentEmployees: number;
    permanentEmployeesInCreeFirstNation: number;
    fixedTermEmployees: number;
    fixedTermEmployeesInCreeFirstNation: number;
    specificCircumstancesOfEmployeesAvailability: string;
    hasOfferedAnyTrainingProgramWithinPastTwelveMonths: boolean;
    trainingType: string;
    hasHiredAnyNewPersonnelWithinPastTwelveMonths: boolean;
    previousRecruitmentCampaigns: string;
  };
  clientele: {
    percentageOfCreeMembers: number;
    names: Array<{
      name: string;
    }>;
  };
  annualPayroll: number;
  estimatedYearlyRevenue: {
    data: {
      id: number;
      attributes: {
        name: string;
        locale: string;
        localizations: Localizations
      };
    };
  };
  authorizationToOperateInCategoryIA: {
    notApplicable: boolean;
    community: {
      data: {
        id: number;
        attributes: {
          name: string;
        };
      };
    };
  };
};

export type BusinessAccountingAttributes = {
  internalProcess: boolean;
  nameOfPersonOrEntity: string;
  address: BusinessAddressAttributes;
};

export type BusinessAddressAttributes = {
  address: string;
  suiteApartmentPoBox: string;
  city: string;
  province: {
    data: {
      id: number;
      attributes: {
        name: string;
        locale: string;
        localizations: Localizations
      };
    };
  };
  postalCode: string | null;
};

export type Localizations = {
  data: [{
    id: number;
    attributes: {
      name: string;
      locale: string;
    }
  }]
}


export enum businessRegistryStatusEnum {
  draft = 'Draft',
  denied = 'Denied',
  pendingForApproval = 'Pending_For_Approval',
  published = 'Published',
  dueForRenewal = 'Due_For_Renewal',
  outOfBusiness = 'Out_Of_Business',
}
