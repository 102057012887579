import { opportunityStatusEnum } from '../types/opportunities';

export function getOpportunityStatus(
  status: string,
  publishedAt: Date
) {
  const draftCompatibleStatus: string[] = [
    opportunityStatusEnum.denied,
    opportunityStatusEnum.pendingForApproval,
  ];
  const publishedCompatibleStatus: string[] = [
    opportunityStatusEnum.open,
    opportunityStatusEnum.closed,
    opportunityStatusEnum.closedAndGranted,
  ];

  const predicates = [
    {
      condition: (!publishedAt && draftCompatibleStatus.includes(status)) || (publishedAt && publishedCompatibleStatus.includes(status)),
      value: status,
    },
    {
      condition: publishedAt,
      value: opportunityStatusEnum.open,
    },
  ];

  return (
    predicates.find((p) => p.condition)?.value || opportunityStatusEnum.draft
  );
}
