import { businessRegistryStatusEnum } from '../types/businessRegistry';
import { opportunityStatusEnum } from '../types/opportunities';

export function getCssClassFromOpportunityStatus(status?: string): string {
  switch (status) {
    case opportunityStatusEnum.draft:
    case opportunityStatusEnum.denied:
    case opportunityStatusEnum.closed:
    case opportunityStatusEnum.open:
      return status.toLowerCase();
    case opportunityStatusEnum.pendingForApproval:
      return 'pending-for-approval';
    case opportunityStatusEnum.closedAndGranted:
      return 'closed-and-granted';
    default:
      return '';
  }
}

export function getCssClassFromBusinessStatus(status?: string): string {
  switch (status) {
    case businessRegistryStatusEnum.draft:
    case businessRegistryStatusEnum.denied:
    case businessRegistryStatusEnum.published:
      return status.toLowerCase();
    case businessRegistryStatusEnum.pendingForApproval:
      return 'pending-for-approval';
    case businessRegistryStatusEnum.dueForRenewal:
      return 'due-for-renewal';
    case businessRegistryStatusEnum.outOfBusiness:
      return 'out-of-business';
    default:
      return '';
  }
}
