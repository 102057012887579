import qs, { ParsedQs } from 'qs';
import React, { useContext, useEffect, useState } from 'react';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import CircleRight from '../../../images/circle_right.svg';
import { OpportunitiesListData } from '../../../types/auth';
import { isBrowser } from '../../../utils/browser';
import { getCssClassFromOpportunityStatus } from '../../../utils/cssUtils';
import { formatDateMMMDDYYYY } from '../../../utils/formatStrings';
import { LocaleType } from '../../../utils/i18n';
import { generateKey } from '../../../utils/keys';
import { getOpportunityStatus } from '../../../utils/opportunity';
import Loading from '../../global/loading/loading';
import { Paginations, LayoutType } from '../../global/pagination/pagination';
import Heading from '../general/heading/heading';
import './opportunities.scss';

const Opportunities: React.FC = () => {
  const { entitiesList, i18n, pageDataLoading } = useContext(GlobalContext);
  const currentLocale: LocaleType = useLocaleFromRouter();

  const opportunities = entitiesList as OpportunitiesListData;

  const itemsPerPage = 8;
  const numPages = Math.ceil(opportunities.length / itemsPerPage);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [list, setList] = useState<OpportunitiesListData>(
    opportunities.slice(0, itemsPerPage * currentPage)
  );

  // Set to first page in case if list change (ex: form user selection)
  useEffect(() => {
    setCurrentPage(1);
    setList(opportunities.slice(0, itemsPerPage * 1));
  }, [opportunities]);

  useEffect(() => {
    setList(
      opportunities.slice(
        (currentPage - 1) * itemsPerPage,
        itemsPerPage * currentPage
      )
    );
  }, [currentPage]);

  // Set page based off url params
  useEffect(() => {
    const locationSearchState: ParsedQs = qs.parse(location.search.slice(1));
    if (locationSearchState) {
      const qpage = Number(locationSearchState.page);
      let page = 1;
      if (!isNaN(qpage) && qpage > 1) {
        page = qpage;
        if (qpage > numPages) {
          page = numPages;
        }
      }

      setCurrentPage(page);
    }
  }, [location.search]);

  const setPage = (page: number) => {
    if (isBrowser) {
      history.pushState({ page }, '', `?page=${page}`);
    }

    setCurrentPage(page);
  };

  let opportunityEditSlug = i18n?.t('account.opportunity.edit.slug');
  opportunityEditSlug = opportunityEditSlug
    ? opportunityEditSlug
    : 'account/opportunity';

  return (
    <div className="dashboard-opportunities-list-wrapper">
      <Heading label={i18n?.t('dashboard.opportunities.header')} />

      <div
        className={`opportunities-list-container ${
          !pageDataLoading && numPages > 1 ? ' with-pagination' : ''
        }`}
      >
        <div className="opportunities-table-container">
          <div className="table-heading">
            <h3 className="table-title-cell">
              {i18n?.t('dashboard.opportunities.offer_title')}
            </h3>
            <h3 className="table-other-cell">
              {i18n?.t('dashboard.opportunities.created_by')}
            </h3>
            <h3 className="table-other-cell">
              {i18n?.t('dashboard.opportunities.publication_date')}
            </h3>
            <h3 className="table-other-cell">
              {i18n?.t('dashboard.opportunities.update_date')}
            </h3>
            <h3 className="table-other-cell">
              {i18n?.t('dashboard.opportunities.deadline')}
            </h3>
            <h3 className="table-other-cell">
              {i18n?.t('dashboard.opportunities.status')}
            </h3>
          </div>

          {!pageDataLoading &&
            (list.length ? (
              <>
                {list.map((item) => {
                  if (!item) return null;
                  const status = getOpportunityStatus(
                    item.status,
                    item.publishedAt
                  );

                  const opportunityStatusCssClass =
                    getCssClassFromOpportunityStatus(status);

                  const updatedAtFormattedDate = formatDateMMMDDYYYY(
                    item.updatedAt
                  );

                  const publishedAtFormattedDate = item.publishedAt
                    ? formatDateMMMDDYYYY(item.publishedAt)
                    : '-';
                  const deadlineFormattedDate = item.deadline
                    ? formatDateMMMDDYYYY(new Date(item.deadline + 'T23:59:59'))
                    : '-';

                  let createdBy = 'Cree Gov';
                  if (item.createdByUser) {
                    createdBy = `${item.createdByUser.firstName} ${item.createdByUser.lastName}`;
                  }

                  return (
                    <a
                      key={item.id}
                      className="table-content-row"
                      href={`/${currentLocale}/${opportunityEditSlug}/${item.id}`}
                    >
                      <div className="mobile-top-section">
                        <div className={`status ${opportunityStatusCssClass}`}>
                          {i18n?.t(`status.${opportunityStatusCssClass}`)}
                        </div>
                        <img src={CircleRight} className="circle-right" />
                      </div>

                      <div className="table-title-cell title-cell">
                        <span>{item.title}</span>
                      </div>

                      <div className="table-other-cell table-cell">
                        <span className="mobile-cell-name">
                          {i18n?.t('dashboard.opportunities.created_by')}:
                        </span>
                        <span className="cell-content">{createdBy}</span>
                      </div>

                      <div className="table-other-cell table-cell ">
                        <span className="mobile-cell-name">
                          {i18n?.t('dashboard.opportunities.publication_date')}:
                        </span>
                        <span className="cell-content">
                          {publishedAtFormattedDate ?? '-'}
                        </span>
                      </div>

                      <div className="table-other-cell table-cell ">
                        <span className="mobile-cell-name">
                          {i18n?.t('dashboard.opportunities.update_date')}:
                        </span>
                        <span className="cell-content">
                          {updatedAtFormattedDate}
                        </span>
                      </div>

                      <div className="table-other-cell table-cell">
                        <span className="mobile-cell-name">
                          {i18n?.t('dashboard.opportunities.deadline')}:
                        </span>
                        <span className="cell-content">
                          {deadlineFormattedDate ?? '-'}
                        </span>
                      </div>

                      <div className="table-other-cell table-cell desktop">
                        <div className={`status ${opportunityStatusCssClass}`}>
                          {i18n?.t(`status.${opportunityStatusCssClass}`)}
                        </div>
                      </div>

                      <img src={CircleRight} className="circle-right desktop" />
                    </a>
                  );
                })}
              </>
            ) : (
              <div className="table-content-row item-row empty">
                {i18n?.t('dashboard.opportunities.no_opportunities')}
              </div>
            ))}

          {pageDataLoading && (
            <div className="table-content-row item-row loading-row">
              <Loading size="medium" />
            </div>
          )}
        </div>
      </div>

      {!pageDataLoading && (
        <div className="dashboard-pagination">
          <Paginations
            id={generateKey('dashboard-pagination')}
            limit={itemsPerPage}
            currentPage={currentPage}
            numPages={numPages}
            setPage={setPage}
            layout={LayoutType.square}
          />
        </div>
      )}
    </div>
  );
};

export default Opportunities;
