import React from 'react';

import Opportunities from '../../components/dashboard/opportunities/opportunities';
import DashboardLayout from '../../components/global/dashboard/dashboardLayout/dashboardLayout';
import { AuthPageBase } from '../../context/global-context';
import { AuthPageData, DashboardPageType } from '../../types/auth';
import { LocalizationData } from '../../types/general';
import { PageContext } from '../../types/page';
import { LocaleEnum, getLocales, useI18n } from '../../utils/i18n';

type DashboardOpportunitiesContext = PageContext & {
  locale: LocaleEnum;
  slug: string;
};

const DashboardOpportunities: React.FC<{
  pageContext: DashboardOpportunitiesContext;
}> = ({ pageContext }) => {
  const { translations, locale, slug } = pageContext;

  const i18n = useI18n(translations);
  const locales = getLocales([locale]);
  const localizationsData: LocalizationData[] = [];

  locales.forEach((l) => {
    let slug = i18n.t('account.opportunities.slug', l);
    slug = slug ? slug : 'account/opportunities';

    localizationsData.push({
      attributes: {
        slug: slug,
        locale: l,
      },
    });
  });

  const instance: AuthPageData = {
    dashboardPageType: DashboardPageType.opportunitiesList,
    manageOpportunitiesPage: true,
    attributes: {
      slug: slug,
      locale: locale,
      localizations: {
        data: localizationsData,
      },
    },
  };

  return (
    <AuthPageBase translations={translations} instance={instance}>
      <DashboardLayout>
        <Opportunities />
      </DashboardLayout>
    </AuthPageBase>
  );
};

export default DashboardOpportunities;
