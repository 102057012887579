import React from "react";
import { Pagination } from 'react-bootstrap';

import './pagination.scss';

export enum LayoutType {
  circle = 'circle-layout',
  square = 'square-layout'
}

type PaginationsProps = {
  id: string;
  currentPage: number;
  limit: number;
  numPages: number;
  setPage?: (page: number) => void;
  skip?: number;
  layout?: LayoutType
}

export const Paginations : React.FC<PaginationsProps> = ({
  layout= LayoutType.circle,
  ...props
}): JSX.Element => {
  const { numPages, currentPage, setPage, id } = props;
  const changePage = (event: React.MouseEvent, newPage: number) => {
    if (!setPage) {
      return;
    }

    event.preventDefault();
    setPage(newPage - 1);
  };

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const renderPagination = (): JSX.Element => {
    return (
      <Pagination id={id} className={layout}>
        {/* previous arrow */}
        {currentPage !== 1 && (
          <Pagination.Prev
            href={`${currentPage - 1}`}
            onClick={(event) => {
              scrollToTop();
              changePage(event, currentPage - 1);
            }}
          />
        )}

        {/* first page */}
        {currentPage > 2 && (
          <Pagination.Item
            href={`${1}`}
            onClick={(event) => {
              scrollToTop();
              changePage(event, 1);
            }}
          >
            {1}
          </Pagination.Item>
        )}

        {/* ellipsis before */}
          {currentPage - 2 > 0 && numPages > 3 && (
          <Pagination.Ellipsis disabled className="ellipsis" />
        )}

        {/* page before previous page */}
        {currentPage === numPages && numPages > 3 && currentPage - 2 > 0 && (
          <Pagination.Item
            href={`${currentPage - 2}`}
            onClick={(event) => {
              scrollToTop();
              changePage(event, currentPage - 2);
            }}
            className={`${currentPage - 2 >= 10 ? 'two-digits' : 'one-digit'} `}
          >
            {currentPage - 2}
          </Pagination.Item>
        )}

        {/* previous page */}
        {currentPage - 1 > 0 && (
          <Pagination.Item
            href={`${currentPage - 1}`}
            onClick={(event) => {
              scrollToTop();
              changePage(event, currentPage - 1);
            }}
            className={`${currentPage - 1 >= 10 ? 'two-digits' : 'one-digit'} `}
          >
            {currentPage - 1}
          </Pagination.Item>
        )}

        {/* current page */}
        <Pagination.Item
          className={`is-active ${
            currentPage >= 10 ? 'two-digits' : 'one-digit'
          }`}
        >
          {currentPage}
        </Pagination.Item>

        {/* next page */}
        {currentPage + 1 < numPages && (
          <Pagination.Item
            href={`${currentPage + 1}`}
            onClick={(event) => {
              scrollToTop();
              changePage(event, currentPage + 1);
            }}
            className={`${currentPage + 1 >= 10 ? 'two-digits' : 'one-digit'} `}
          >
            {currentPage + 1}
          </Pagination.Item>
        )}

        {/* page after next page */}
        {currentPage === 1 && currentPage + 2 < numPages && (
          <Pagination.Item
            href={`${currentPage + 2}`}
            onClick={(event) => {
              scrollToTop();
              changePage(event, currentPage + 2);
            }}
            className={`${currentPage + 2 >= 10 ? 'two-digits' : 'one-digit'} `}
          >
            {currentPage + 2}
          </Pagination.Item>
        )}

        {/* ellipsis after */}
        {currentPage + 2 < numPages && (
          <Pagination.Ellipsis disabled className="ellipsis" />
        )}

        {/* last page */}
        {currentPage != numPages && (
          <Pagination.Item
            href={`${numPages}`}
            onClick={(event) => {
              scrollToTop();
              changePage(event, numPages);
            }}
            className={`${numPages >= 10 ? 'two-digits' : 'one-digit'} `}
          >
            {numPages}
          </Pagination.Item>
        )}

        {/* next page arrow */}
        {currentPage !== numPages && (
          <Pagination.Next
            href={`${currentPage + 1}`}
            onClick={(event) => {
              scrollToTop();
              changePage(event, currentPage + 1);
            }}
          />
        )}
      </Pagination>
    )
  };

  return (
    <div>{numPages > 1 ? renderPagination() : ''}</div>
  );
}